import { Admin, Resource, CustomRoutes, defaultTheme } from "react-admin";
import { Route } from "react-router-dom";
import { JsonImport } from "./jsonImport";
import { ArtistCreate, ArtistEdit, ArtistList, ArtistShow } from "./artists";
import { dataProvider } from "./dataProvider";
import { AlbumCreate, AlbumEdit, AlbumList, AlbumShow } from "./albums";
import { TrackCreate, TrackEdit, TrackList, TrackShow } from "./tracks";
import { authProvider } from "./auth";
import Dashboard from "./dashboard";
import { GenreCreate, GenreEdit, GenreList, GenreShow } from "./genres";
import { CustomLogin } from "./login";
import { ArtistPayments } from "./artistsPayment";
import { initSentry } from "./sentry";
import { CheckForApplicationUpdate, Layout, LayoutProps } from "react-admin";
import indigo from "@mui/material/colors/indigo";
import pink from "@mui/material/colors/pink";
import red from "@mui/material/colors/red";
import { LabelList, LabelEdit, LabelShow, LabelCreate } from "./labels";
import { DDEXMessageCreate, DDEXMessageList, DDEXMessageShow } from "./ddexMessages";
import { DDEXDryRunList, DDEXDryRunShow } from "./ddexDryRuns";
import { UserFeedbackList, UserFeedbackShow } from "./userFeedbacks";
import { TagCreate, TagEdit, TagList, TagShow } from "./tags";
import {
    DDEXGenreMappingCreate,
    DDEXGenreMappingEdit,
    DDEXGenreMappingList,
    DDEXGenreMappingShow,
} from "./ddexGenreMappings";
import { CuratedTracks, FeaturedTracks } from "./featuredTracks";
import { AppMenu } from "./menu";
import {
    DDEXArtistMappingCreate,
    DDEXArtistMappingEdit,
    DDEXArtistMappingList,
    DDEXArtistMappingShow,
} from "./ddexArtistMappings";
import { ConfigEdit } from "./config";
import { ExportList, ExportShow } from "./exports";
import { DistributorCreate, DistributorEdit, DistributorList, DistributorShow } from "./distributors";
import { captureMessage } from "@sentry/react";
import { PlaylistEdit, PlaylistList, PlaylistShow } from "./playlists";
import { FreeUnlockCreate, FreeUnlockEdit, FreeUnlockList, FreeUnlockShow } from "./freeUnlocks";
import { PromoCodeCreate, PromoCodeEdit, PromoCodeList, PromoCodeShow } from "./promoCodes";
import { BackgroundProcessingList, BackgroundProcessingShow } from "./backgroundProcessings";

initSentry();

const VerifyAppUpdateLayout = ({ children, ...props }: LayoutProps) => (
    <Layout {...props} menu={AppMenu}>
        {children}
        <CheckForApplicationUpdate />
    </Layout>
);

let theme = defaultTheme;
if (import.meta.env.MODE !== "production") {
    theme = {
        ...theme,
        palette: {
            primary: indigo,
            secondary: pink,
            error: red,
        },
    };
}

const AdminResources = () => [
    <Resource
        name="artists"
        list={ArtistList}
        show={ArtistShow}
        edit={ArtistEdit}
        create={ArtistCreate}
        recordRepresentation="name"
    />,
    <Resource
        name="albums"
        list={AlbumList}
        show={AlbumShow}
        edit={AlbumEdit}
        create={AlbumCreate}
        recordRepresentation="name"
    />,
    <Resource
        name="tracks"
        list={TrackList}
        show={TrackShow}
        edit={TrackEdit}
        create={TrackCreate}
        recordRepresentation="name"
    />,
    <Resource
        name="genres"
        list={GenreList}
        show={GenreShow}
        edit={GenreEdit}
        create={GenreCreate}
        recordRepresentation="name"
    />,
    <Resource
        name="labels"
        list={LabelList}
        show={LabelShow}
        edit={LabelEdit}
        create={LabelCreate}
        recordRepresentation="name"
    />,
    <Resource
        name="ddex/messages"
        list={DDEXMessageList}
        show={DDEXMessageShow}
        create={DDEXMessageCreate}
        recordRepresentation="id"
        options={{ label: "DDEX Messages" }}
    />,
    <Resource
        name="ddex/dry_runs"
        list={DDEXDryRunList}
        show={DDEXDryRunShow}
        recordRepresentation="id"
        options={{ label: "DDEX Dry Runs" }}
    />,
    <Resource
        name="ddex/genre_mappings"
        list={DDEXGenreMappingList}
        show={DDEXGenreMappingShow}
        create={DDEXGenreMappingCreate}
        edit={DDEXGenreMappingEdit}
        recordRepresentation="ddex_name"
        options={{ label: "DDEX Genre Mapping" }}
    />,
    <Resource
        name="ddex/artist_mappings"
        list={DDEXArtistMappingList}
        show={DDEXArtistMappingShow}
        create={DDEXArtistMappingCreate}
        edit={DDEXArtistMappingEdit}
        recordRepresentation="name"
        options={{ label: "DDEX Artist Mapping" }}
    />,
    <Resource
        name="user_feedbacks"
        list={UserFeedbackList}
        show={UserFeedbackShow}
        recordRepresentation="id"
    />,
    <Resource
        name="tags"
        list={TagList}
        show={TagShow}
        edit={TagEdit}
        create={TagCreate}
        recordRepresentation="name"
    />,
    <Resource name="exports" list={ExportList} show={ExportShow} recordRepresentation="name" />,
    <Resource
        name="distributors"
        list={DistributorList}
        show={DistributorShow}
        create={DistributorCreate}
        edit={DistributorEdit}
        recordRepresentation="name"
    />,
    <Resource
        name="playlists"
        list={PlaylistList}
        show={PlaylistShow}
        edit={PlaylistEdit}
        recordRepresentation="name"
    />,
    <Resource
        name="free_unlocks"
        list={FreeUnlockList}
        show={FreeUnlockShow}
        create={FreeUnlockCreate}
        edit={FreeUnlockEdit}
        recordRepresentation="id"
    />,
    <Resource
        name="promo_codes"
        list={PromoCodeList}
        show={PromoCodeShow}
        create={PromoCodeCreate}
        edit={PromoCodeEdit}
        recordRepresentation="code"
    />,
    <Resource
        name="background_processings"
        list={BackgroundProcessingList}
        show={BackgroundProcessingShow}
        recordRepresentation="id"
        options={{ label: "Background Processing" }}
    />,
    <CustomRoutes>
        <Route path="/payments" element={<ArtistPayments />} />
        <Route path="/featured_tracks" element={<FeaturedTracks />} />
        <Route path="/curated_tracks" element={<CuratedTracks />} />
        <Route path="/config" element={<ConfigEdit />} />
        <Route path="/json-import" element={<JsonImport />} />
    </CustomRoutes>,
];

const getResources = (permissions: any) => {
    if (!permissions) {
        return [];
    }

    switch (permissions.mode) {
        case "admin": {
            return AdminResources();
        }
    }

    captureMessage(`Unsupported permissions.mode: ${permissions.mode}`);
};

export const App = () => (
    <Admin
        theme={theme}
        layout={VerifyAppUpdateLayout}
        loginPage={CustomLogin}
        dashboard={Dashboard}
        dataProvider={dataProvider}
        authProvider={authProvider}
        requireAuth
        disableTelemetry
    >
        {getResources}
    </Admin>
);
