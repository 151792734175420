import {
    Create,
    Datagrid,
    List,
    Edit,
    Show,
    SimpleShowLayout,
    TextField,
    Pagination,
    ReferenceField,
    DateTimeInput,
    required,
    BooleanField,
} from "react-admin";
import { SimpleForm, TextInput } from "react-admin";
import { RelativeDateTimeField, TrackReferenceInput } from "./customFields";
import { BulkActionButtons } from "./common";

const freeUnlockFilters = [<TextInput source="user_id" label="User ID" />];

export const FreeUnlockList = () => (
    <List
        filters={freeUnlockFilters}
        perPage={50}
        pagination={<Pagination rowsPerPageOptions={[25, 50, 100, 200]} />}
        sort={{ field: "created_at", order: "DESC" }}
    >
        <Datagrid rowClick="show" bulkActionButtons={<BulkActionButtons />}>
            <TextField source="user_id" />
            <ReferenceField source="track_id" reference="tracks" />
            <RelativeDateTimeField source="available_start_time" />
            <RelativeDateTimeField source="available_end_time" />
            <BooleanField source="has_been_used" />
        </Datagrid>
    </List>
);

export const FreeUnlockShow = () => (
    <Show>
        <SimpleShowLayout>
            <h2>FreeUnlock</h2>

            <TextField source="id" />
            <TextField source="user_id" />
            <RelativeDateTimeField source="available_start_time" />
            <RelativeDateTimeField source="available_end_time" />
            <ReferenceField source="track_id" reference="tracks" />
            <BooleanField source="has_been_used" />
        </SimpleShowLayout>
    </Show>
);

export const FreeUnlockCreate = () => (
    <Create>
        <SimpleForm>
            <h2>Add FreeUnlock</h2>
            <TextInput source="user_id" fullWidth validate={[required()]} label="User ID" />
            <DateTimeInput source="available_start_time" validate={[required()]} />
            <DateTimeInput source="available_end_time" validate={[required()]} />
        </SimpleForm>
    </Create>
);

export const FreeUnlockEdit = () => (
    <Edit mutationMode="pessimistic">
        <SimpleForm>
            <h2>Edit FreeUnlock</h2>

            <DateTimeInput source="available_start_time" validate={[required()]} />
            <DateTimeInput source="available_end_time" validate={[required()]} />
        </SimpleForm>
    </Edit>
);
