import {
    Datagrid,
    List,
    Show,
    SimpleShowLayout,
    TextField,
    Pagination,
    useNotify,
    useRefresh,
    Confirm,
    useRecordContext,
} from "react-admin";
import { TextInput } from "react-admin";
import { RelativeDateTimeField, ShortDateField } from "./customFields";
import { BulkActionButtons } from "./common";
import { useState } from "react";
import { httpClient } from "./dataProvider";
import ActionCheck from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import { Button } from "@mui/material";

const ApplyButton = () => {
    const notify = useNotify();
    const [isLoading, setLoading] = useState(false);
    const [open, setOpen] = useState(false);
    const refresh = useRefresh();

    const handleClick = () => setOpen(true);
    const handleDialogClose = () => setOpen(false);
    const handleConfirm = () => {
        httpClient(import.meta.env.VITE_SERVER_URL + `/cms/ddex/reprocess_outdated_ddex_messages`, {
            method: "POST",
        })
            .then(() => {
                setLoading(false);
                setOpen(false);
                refresh();
            })
            .catch((error) => {
                notify(error.toString(), { type: "error" });
                setLoading(false);
                setOpen(false);
            });
    };

    return (
        <>
            <Confirm
                isOpen={open}
                loading={isLoading}
                title={`Reprocess outdated DDEX messages`}
                content="Are you sure you want to reprocess outdated DDEX messages?"
                onConfirm={handleConfirm}
                onClose={handleDialogClose}
                ConfirmIcon={ActionCheck}
                CancelIcon={CancelIcon}
            />
            <Button
                onClick={handleClick}
                disabled={isLoading}
                variant="contained"
                color="primary"
                sx={{ width: "400px", margin: "10px" }}
            >
                Reprocess outdated DDEX messages
            </Button>
        </>
    );
};

const CancelButton = () => {
    const record = useRecordContext();
    const notify = useNotify();
    const [isLoading, setLoading] = useState(false);
    const [open, setOpen] = useState(false);
    const refresh = useRefresh();

    const handleClick = () => setOpen(true);
    const handleDialogClose = () => setOpen(false);
    const handleConfirm = () => {
        httpClient(import.meta.env.VITE_SERVER_URL + `/cms/background_processing/${record.id}/cancel`, {
            method: "POST",
        })
            .then(() => {
                setLoading(false);
                setOpen(false);
                refresh();
            })
            .catch((error) => {
                notify(error.toString(), { type: "error" });
                setLoading(false);
                setOpen(false);
            });
    };

    if (!record || record.status !== "in_progress") return null;
    return (
        <>
            <Confirm
                isOpen={open}
                loading={isLoading}
                title={`Cancel Task #${record && record.id}`}
                content="Are you sure you want to cancel this task?"
                onConfirm={handleConfirm}
                onClose={handleDialogClose}
                ConfirmIcon={ActionCheck}
                CancelIcon={CancelIcon}
            />
            <Button onClick={handleClick} disabled={isLoading} variant="contained" color="primary">
                Cancel
            </Button>
        </>
    );
};

export const BackgroundProcessingList = () => (
    <>
        <ApplyButton />
        <List
            perPage={50}
            pagination={<Pagination rowsPerPageOptions={[25, 50, 100, 200]} />}
            sort={{ field: "created_at", order: "DESC" }}
        >
            <Datagrid rowClick="show" bulkActionButtons={<BulkActionButtons />}>
                <TextField source="id" />
                <TextField source="name" />
                <TextField source="status" />
                <TextField source="progress" />
                <ShortDateField source="created_at" label="Added on" />
            </Datagrid>
        </List>
    </>
);

export const BackgroundProcessingShow = () => (
    <Show>
        <SimpleShowLayout>
            <h2>BackgroundProcessing</h2>
            <TextField source="id" />
            <TextField source="name" />
            <TextField source="status" />
            <TextField source="progress" />
            <TextField source="current" />
            <TextField source="total" />
            <RelativeDateTimeField source="created_at" />
            <CancelButton />
        </SimpleShowLayout>
    </Show>
);
