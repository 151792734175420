import { useState } from "react";
import { Card, CardContent, Typography, Button, Box, TextField } from "@mui/material";
import { useNavigate } from "react-router-dom";

export const JsonImport = () => {
    const [jsonInput, setJsonInput] = useState("");
    const [error, setError] = useState("");
    const navigate = useNavigate();

    const handleSubmit = () => {
        try {
            // Validate JSON
            JSON.parse(jsonInput);

            // Redirect to track creation with JSON data
            navigate(`/tracks/create?source=${encodeURIComponent(jsonInput)}`);
        } catch (e) {
            setError("Invalid JSON format. Please check your input.");
        }
    };

    return (
        <Card>
            <CardContent>
                <Typography variant="h5" gutterBottom>
                    Import Track from JSON
                </Typography>
                <Typography variant="body2" color="textSecondary" paragraph>
                    Paste your JSON data below to create a new track.
                </Typography>

                <TextField
                    label="JSON Data"
                    multiline
                    rows={10}
                    fullWidth
                    variant="outlined"
                    value={jsonInput}
                    onChange={(e) => {
                        setJsonInput(e.target.value);
                        setError("");
                    }}
                    error={!!error}
                    helperText={error}
                    sx={{ mb: 2 }}
                />

                <Box display="flex" justifyContent="flex-end">
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleSubmit}
                        disabled={!jsonInput.trim()}
                    >
                        Create Track
                    </Button>
                </Box>
            </CardContent>
        </Card>
    );
};
