import {
    Create,
    Datagrid,
    List,
    Edit,
    Show,
    SimpleShowLayout,
    TextField,
    Pagination,
    SelectField,
    SelectInput,
    required,
    DateTimeInput,
} from "react-admin";
import { SimpleForm, TextInput } from "react-admin";
import { RelativeDateTimeField, ShortDateField } from "./customFields";
import { BulkActionButtons } from "./common";

const promoCodeFilters = [<TextInput source="q" label="Search" alwaysOn />];
const promoTypes = [
    { id: "trial_2_months", name: "Trial 2 Month" },
    { id: "trial_3_months", name: "Trial 3 Month" },
];

export const PromoCodeList = () => (
    <List
        filters={promoCodeFilters}
        perPage={50}
        pagination={<Pagination rowsPerPageOptions={[25, 50, 100, 200]} />}
        sort={{ field: "created_at", order: "DESC" }}
    >
        <Datagrid rowClick="show" bulkActionButtons={<BulkActionButtons />}>
            <TextField source="code" />
            <SelectField choices={promoTypes} source="promotion_type" />
            <TextField source="used_count" />
            <ShortDateField source="created_at" label="Added on" />
        </Datagrid>
    </List>
);

export const PromoCodeShow = () => (
    <Show>
        <SimpleShowLayout>
            <h2>PromoCode</h2>
            <TextField source="id" />
            <TextField source="code" />
            <SelectField choices={promoTypes} source="promotion_type" />
            <TextField source="used_count" />
            <ShortDateField source="created_at" label="Added on" />
            <RelativeDateTimeField source="expiration_date" />
        </SimpleShowLayout>
    </Show>
);

export const PromoCodeCreate = () => (
    <Create>
        <SimpleForm>
            <h2>Add PromoCode</h2>
            <TextInput source="code" fullWidth validate={[required()]} />
            <SelectInput choices={promoTypes} source="promotion_type" validate={[required()]} />
            <DateTimeInput source="expiration_date" />
        </SimpleForm>
    </Create>
);

export const PromoCodeEdit = () => (
    <Edit mutationMode="pessimistic">
        <SimpleForm>
            <h2>Edit PromoCode</h2>
            <TextInput source="code" fullWidth validate={[required()]} />
            <SelectInput choices={promoTypes} source="promotion_type" validate={[required()]} />
            <DateTimeInput source="expiration_date" />
        </SimpleForm>
    </Edit>
);
