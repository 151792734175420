import {
    Create,
    Datagrid,
    EditButton,
    ImageField,
    List,
    ReferenceManyField,
    Show,
    SimpleShowLayout,
    TextField,
    Pagination,
    required,
    BooleanField,
    BooleanInput,
    ReferenceArrayInput,
    ReferenceArrayField,
    ArrayField,
    SingleFieldList,
    ChipField,
    useRecordContext,
    AutocompleteArrayInput,
    downloadCSV,
    TopToolbar,
    FilterButton,
    CreateButton,
    ExportButton,
    useUpdate,
    Confirm,
    useNotify,
    ArrayInput,
    SimpleFormIterator,
    useCreateSuggestionContext,
    useCreate,
    LinearProgress,
    ReferenceManyCount,
} from "react-admin";
import { Edit, SimpleForm, TextInput } from "react-admin";
import {
    DurationField,
    LongDateField,
    MediaUploadField,
    ReferenceWithImageInput,
    SaveAndAddAnotherToolbar,
    ShareLink,
    ShortDateField,
    TrackReferenceInput,
} from "./customFields";
import { Analytics } from "./analytics";
import { captureError } from "./sentry";
import { VisibilityBulkActionButtons } from "./common";
import { DEFAULT_IS_PUBLIC } from "./config";
import jsonExport from "jsonexport/dist";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { PlaylistReferenceInput } from "./playlists";
import { Dialog, Button, DialogActions, DialogContent, TextField as MuiTextField } from "@mui/material";

const artistFilters = [
    <TextInput source="q" label="Search" alwaysOn />,
    <BooleanInput source="is_public" label="Public" defaultValue={true} />,
    <BooleanInput source="has_image" label="Has image" defaultValue={true} />,
    <ReferenceArrayInput source="genre_ids" label="Genre" reference="genres" />,
];

const exporter = async (artists: any) => {
    const data = artists.map((artist: any) => ({
        id: artist.id,
        name: artist.name,
        is_public: artist.is_public,
        image_url: artist.image_url,
    }));

    jsonExport(
        data,
        {
            headers: ["id", "name", "is_public", "image_url"],
        },
        (err: any, csv: any) => {
            downloadCSV(csv, "artists");
        }
    );
};

const ArtistActions = () => (
    <TopToolbar>
        <FilterButton />
        <CreateButton />
        <ExportButton maxResults={50000} />
    </TopToolbar>
);

const RemoveImageButton = () => {
    const record = useRecordContext();
    const notify = useNotify();
    const [open, setOpen] = useState(false);

    const onError = (error: Error) => {
        notify(error.toString(), { type: "error" });
    };

    const updatedData = { ...record, image_upload_id: null };
    const [update, { isLoading }] = useUpdate("artists", { id: record.id, data: updatedData }, { onError });

    const handleClick = () => setOpen(true);
    const handleDialogClose = () => setOpen(false);

    const handleConfirm = () => {
        update();
        setOpen(false);
    };

    return (
        <>
            {record.image_upload_id && (
                <Button onClick={handleClick} variant="contained" color="secondary" size="small">
                    Remove image
                </Button>
            )}
            <Confirm
                isOpen={open}
                loading={isLoading}
                title={`Delete artist image`}
                content="Are you sure you want to delete this artist image?"
                onConfirm={handleConfirm}
                onClose={handleDialogClose}
            />
        </>
    );
};

export const ArtistList = () => (
    <List
        filters={artistFilters}
        perPage={50}
        pagination={<Pagination rowsPerPageOptions={[25, 50, 100, 200]} />}
        sort={{ field: "created_at", order: "DESC" }}
        exporter={exporter}
        actions={<ArtistActions />}
    >
        <Datagrid rowClick="show" bulkActionButtons={<VisibilityBulkActionButtons />}>
            <TextField source="name" />
            <ImageField source="thumbnail_url" title="Artist image" label="Picture" sortable={false} />
            <TextField source="monthly_listener_count" label="Listener count" />
            <BooleanField source="is_public" label="Public" />
            <ShortDateField source="created_at" label="Added on" />
            <EditButton />
        </Datagrid>
    </List>
);

export const ArtistShow = () => (
    <Show>
        <SimpleShowLayout>
            <h2>Artist Details</h2>
            <TextField source="name" />
            <ImageField source="image_url" title="Artist image" label="Picture" />
            <RemoveImageButton />
            <ArrayField source="status" label="Processing Status">
                <SingleFieldList linkType={false}>
                    <ChipField source="name" size="small" />
                </SingleFieldList>
            </ArrayField>
            <TextField source="description" />
            <BooleanField source="is_public" />
            <LongDateField source="created_at" label="Added on" />
            <TextField source="monthly_listener_count" label="Listener count" />
            <TextField source="id" />
            <ShareLink type="artist" />
            <Analytics resource="artists" />
            <ReferenceManyCount label="Nb tracks" reference="tracks" target="artist_id" link />
            <ReferenceManyField label="Tracks preview (25 max)" reference="tracks" target="artist_id">
                <Datagrid rowClick="show">
                    <TextField source="name" />
                    <ImageField source="thumbnail_url" title="Artist image" label="Artwork" />
                    <TextField source="stream_count" label="Stream count" />
                    <BooleanField source="is_available" label="Available" />
                    <ShortDateField source="release_date" label="Release date" />
                    <ReferenceArrayField label="Genres" reference="genres" source="genre_ids" />
                    <EditButton />
                </Datagrid>
            </ReferenceManyField>
        </SimpleShowLayout>
    </Show>
);

const validateArtist = (values: any) => {
    try {
        const errors: any = {};
        console.log(values);
        if (values.name && values.name.length > 100) {
            errors.name = `Name too big (${values.name.length}): 100 chars maximum`;
        }
        if (values.description && values.description.length > 1000) {
            errors.description = `Description too big (${values.description.length}): 1000 chars maximum`;
        }

        return errors;
    } catch (error) {
        captureError(error);
        throw error;
    }
};

export const ArtistEdit = () => {
    const { id } = useParams();

    return (
        <Edit mutationMode="pessimistic">
            <SimpleForm validate={validateArtist}>
                <h2>Edit Artist</h2>

                <TextInput
                    sx={{ width: 500 }}
                    autoComplete="off"
                    validate={[required()]}
                    fullWidth
                    source="name"
                    label="Artist Name"
                />
                <TextInput
                    sx={{ width: 500 }}
                    autoComplete="off"
                    multiline={true}
                    fullWidth
                    source="description"
                    label="Artist Description"
                />
                <BooleanInput source="is_public" label="Public" />
                <MediaUploadField mediaType="image" requiresValidation={true} />
                <TextInput sx={{ width: 500 }} source="id" InputProps={{ disabled: true }} />
                <ArrayInput source="top_track_ids" fullWidth label="Top tracks">
                    <SimpleFormIterator inline>
                        <TrackReferenceInput filter={{ artist_id: id }} />
                    </SimpleFormIterator>
                </ArrayInput>
                <ArrayInput source="featured_playlist_ids" fullWidth label="Featured playlists">
                    <SimpleFormIterator inline>
                        <PlaylistReferenceInput />
                    </SimpleFormIterator>
                </ArrayInput>
            </SimpleForm>
        </Edit>
    );
};

export const ArtistCreate = () => (
    <Create>
        <SimpleForm validate={validateArtist} toolbar={<SaveAndAddAnotherToolbar />}>
            <h2>Add Artist</h2>

            <TextInput
                sx={{ width: 500 }}
                autoComplete="off"
                validate={[required()]}
                fullWidth
                source="name"
                label="Artist Name"
            />
            <TextInput
                sx={{ width: 500 }}
                autoComplete="off"
                multiline={true}
                fullWidth
                source="description"
                label="Artist Description"
            />
            <BooleanInput source="is_public" defaultValue={DEFAULT_IS_PUBLIC} />
            <MediaUploadField mediaType="image" requiresValidation={true} />
        </SimpleForm>
    </Create>
);

const ArtistOptionRenderer = () => {
    const record = useRecordContext();
    return (
        <div style={{ display: "flex", gap: "5px", alignItems: "center" }}>
            <img src={record.thumbnail_url} style={{ width: "40px" }} />
            {record.name}
        </div>
    );
};
const artistOptionText = <ArtistOptionRenderer />;
const artistInputText = (choice: any) => `${choice.name}`;

export const ArtistReferenceInput = ({
    label,
    source,
    create = true,
}: {
    label?: string;
    source?: string;
    create?: boolean;
}) => {
    return (
        <ReferenceWithImageInput
            reference="artists"
            label={label || "Artist"}
            source={source || "artist_id"}
            create={create ? <ArtistCreatePopup /> : undefined}
        />
    );
};

export const ManyArtistReferenceInput = (props: { label?: string; source: string }) => {
    const { label = "Artist", source } = props;

    return (
        <ReferenceArrayInput
            fullWidth
            source={source}
            reference="artists"
            label={label}
            sort={{ field: "_search", order: "DESC" }}
        >
            <AutocompleteArrayInput
                inputText={artistInputText}
                optionText={artistOptionText}
                label={label}
                create={<ArtistCreatePopup />}
            />
        </ReferenceArrayInput>
    );
};

export const ArtistCreatePopup = () => {
    const { filter, onCancel, onCreate } = useCreateSuggestionContext();
    const [value, setValue] = useState(filter || "");
    const [create, { isLoading }] = useCreate();
    const notify = useNotify();

    const handleSubmit = (event: any) => {
        event.preventDefault();
        const newArtist = { name: value, is_public: false };

        create("artists", { data: newArtist }, { returnPromise: true })
            .then((value: any) => {
                setValue("");
                onCreate(value);
            })
            .catch((reason: any) => {
                notify(reason);
            });
    };

    return (
        <Dialog open onClose={onCancel}>
            {isLoading ? <LinearProgress></LinearProgress> : null}
            <form onSubmit={handleSubmit}>
                <DialogContent>
                    <MuiTextField
                        label="Artist name"
                        value={value}
                        onChange={(event: any) => setValue(event.target.value)}
                        autoFocus
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={onCancel}>Cancel</Button>
                    <Button type="submit">Save</Button>
                </DialogActions>
            </form>
        </Dialog>
    );
};
