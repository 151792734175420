import { Layout, Menu } from "react-admin";
import SettingsIcon from "@mui/icons-material/Settings";
import CodeIcon from "@mui/icons-material/Code";

export const AppMenu = () => (
    <Menu>
        <Menu.DashboardItem />
        <Menu.ResourceItems />
        <Menu.Item to="/featured_tracks" primaryText="Featured Tracks" leftIcon={<SettingsIcon />} />
        <Menu.Item to="/curated_tracks" primaryText="Curated Tracks" leftIcon={<SettingsIcon />} />
        <Menu.Item to="/config" primaryText="Config" leftIcon={<SettingsIcon />} />
        <Menu.Item to="/json-import" primaryText="Track Import" leftIcon={<CodeIcon />} />
    </Menu>
);
