import {
    Create,
    Datagrid,
    List,
    Edit,
    Show,
    SimpleShowLayout,
    TextField,
    Pagination,
    BooleanField,
    BooleanInput,
    useRecordContext,
    useNotify,
    useRefresh,
    Confirm,
    useCreateSuggestionContext,
    useCreate,
    LinearProgress,
} from "react-admin";
import { SimpleForm, TextInput } from "react-admin";
import { LongDateField, ShortDateField } from "./customFields";
import { BulkActionButtons } from "./common";
import { useState } from "react";
import { httpClient } from "./dataProvider";
import ActionCheck from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import { Button, Dialog, DialogActions, DialogContent, TextField as MuiTextField } from "@mui/material";

const distributorFilters = [<TextInput source="q" label="Search" alwaysOn />];

export const DistributorList = () => (
    <List
        filters={distributorFilters}
        perPage={50}
        pagination={<Pagination rowsPerPageOptions={[25, 50, 100, 200]} />}
        sort={{ field: "created_at", order: "DESC" }}
    >
        <Datagrid rowClick="show" bulkActionButtons={<BulkActionButtons />}>
            <TextField source="id" />
            <TextField source="name" />
            <TextField source="ddex_party_id" />
            <BooleanField source="is_ddex_supported" />
            <BooleanField source="is_ddex_download_supported" />
            <ShortDateField source="created_at" label="Added on" />
        </Datagrid>
    </List>
);

const ApplyButton = () => {
    const record = useRecordContext();
    const notify = useNotify();
    const [isLoading, setLoading] = useState(false);
    const [open, setOpen] = useState(false);
    const refresh = useRefresh();

    const handleClick = () => setOpen(true);
    const handleDialogClose = () => setOpen(false);
    const handleConfirm = () => {
        httpClient(import.meta.env.VITE_SERVER_URL + `/cms/distributors/${record.id}/apply_ddex_messages`, {
            method: "POST",
        })
            .then(() => {
                setLoading(false);
                setOpen(false);
                refresh();
            })
            .catch((error) => {
                notify(error.toString(), { type: "error" });
                setLoading(false);
                setOpen(false);
            });
    };

    if (!record) return null;
    return (
        <>
            <Confirm
                isOpen={open}
                loading={isLoading}
                title={`Apply messages from distributor ${record.name}`}
                content="Are you sure you want to apply messages from this distributors?"
                onConfirm={handleConfirm}
                onClose={handleDialogClose}
                ConfirmIcon={ActionCheck}
                CancelIcon={CancelIcon}
            />
            <Button onClick={handleClick} disabled={isLoading} variant="contained" color="primary">
                Apply DDEX
            </Button>
        </>
    );
};

export const DistributorShow = () => (
    <Show>
        <SimpleShowLayout>
            <h2>Distributor</h2>

            <TextField source="id" />
            <TextField source="name" />
            <TextField source="ddex_party_id" />
            <BooleanField source="is_ddex_supported" />
            <BooleanField source="is_ddex_download_supported" />
            <LongDateField source="created_at" label="Added on" />
            <ApplyButton />
        </SimpleShowLayout>
    </Show>
);

export const DistributorCreate = () => (
    <Create>
        <SimpleForm>
            <h2>Add Distributor</h2>
            <TextInput source="name" fullWidth />
        </SimpleForm>
    </Create>
);

export const DistributorEdit = () => (
    <Edit mutationMode="pessimistic">
        <SimpleForm>
            <h2>Edit Distributor</h2>

            <TextInput source="name" fullWidth />
            <TextInput source="ddex_party_id" fullWidth label="Party id" />
            <BooleanInput source="is_ddex_supported" />
            <BooleanInput source="is_ddex_download_supported" />
        </SimpleForm>
    </Edit>
);

export const DistributorCreatePopup = () => {
    const { filter, onCancel, onCreate } = useCreateSuggestionContext();
    const [value, setValue] = useState(filter || "");
    const [create, { isLoading }] = useCreate();
    const notify = useNotify();

    const handleSubmit = (event: any) => {
        event.preventDefault();
        const newDistributor = { name: value };

        create("distributors", { data: newDistributor }, { returnPromise: true })
            .then((value: any) => {
                setValue("");
                onCreate(value);
            })
            .catch((reason: any) => {
                notify(reason);
            });
    };

    return (
        <Dialog open onClose={onCancel}>
            {isLoading ? <LinearProgress></LinearProgress> : null}
            <form onSubmit={handleSubmit}>
                <DialogContent>
                    <MuiTextField
                        label="Distributor name"
                        value={value}
                        onChange={(event: any) => setValue(event.target.value)}
                        autoFocus
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={onCancel}>Cancel</Button>
                    <Button type="submit">Save</Button>
                </DialogActions>
            </form>
        </Dialog>
    );
};
