import {
    Datagrid,
    List,
    Edit,
    Show,
    SimpleShowLayout,
    TextField,
    Pagination,
    BooleanField,
    BooleanInput,
    ImageField,
    ReferenceArrayField,
    ChipField,
    useRecordContext,
} from "react-admin";
import { SimpleForm, TextInput } from "react-admin";
import {
    DurationField,
    LongDateField,
    MediaUploadField,
    ReferenceWithImageInput,
    ShareLink,
    ShortDateField,
} from "./customFields";
import { OrderedSingleFieldList } from "./orderedSingleFieldList";

const playlistFilters = [
    <TextInput source="q" label="Search" alwaysOn />,
    <TextInput source="email" label="User email" />,
    <TextInput source="user_id" label="User ID" />,
    <BooleanInput source="featured" label="Featured" />,
];

export const PlaylistList = () => (
    <List
        filters={playlistFilters}
        perPage={50}
        pagination={<Pagination rowsPerPageOptions={[25, 50, 100, 200]} />}
        sort={{ field: "created_at", order: "DESC" }}
    >
        <Datagrid rowClick="show" bulkActionButtons={false}>
            <TextField source="name" />
            <TextField source="user_email" />
            <ImageField source="thumbnail_url" title="Playlist image" label="Image" sortable={false} />
            <BooleanField source="featured" />
            <ShortDateField source="created_at" label="Created at" />
        </Datagrid>
    </List>
);

export const PlaylistShow = () => (
    <Show>
        <SimpleShowLayout>
            <h2>Playlist</h2>

            <TextField source="id" />
            <TextField source="name" />
            <TextField source="user_email" />
            <TextField source="user_id" />
            <ImageField source="image_url" title="Playlist image" label="Image" sortable={false} />
            <BooleanField source="featured" />
            <LongDateField source="created_at" label="Created at" />
            <ShareLink type="playlist" />
            <ReferenceArrayField label="Tracks" reference="tracks" source="track_ids">
                <Datagrid rowClick="show">
                    <TextField source="name" />
                    <ImageField source="thumbnail_url" title="Album cover" label="Image" />
                    <ReferenceArrayField
                        label="Artists"
                        reference="artists"
                        source="artist_ids"
                        sortable={false}
                    >
                        <OrderedSingleFieldList source="artist_ids">
                            <ChipField source="name" size="small" />
                        </OrderedSingleFieldList>
                    </ReferenceArrayField>
                    <ReferenceArrayField
                        label="Genres"
                        reference="genres"
                        source="genre_ids"
                        sortable={false}
                    />
                    <BooleanField source="is_public" label="Public" />
                    <DurationField source="duration_ms" label="Duration" />
                </Datagrid>
            </ReferenceArrayField>
        </SimpleShowLayout>
    </Show>
);

export const PlaylistEdit = () => (
    <Edit mutationMode="pessimistic">
        <SimpleForm>
            <h2>Edit Playlist</h2>

            <TextInput source="name" sx={{ width: 500 }} />
            <MediaUploadField mediaType="image" />
            <BooleanInput source="featured" />
        </SimpleForm>
    </Edit>
);

export const PlaylistReferenceInput = (props: { label?: string; source?: string }) => {
    return (
        <ReferenceWithImageInput
            reference="playlists"
            label={props.label || "Playlist"}
            source={props.source || "playlist_id"}
        />
    );
};
