import {
    Create,
    Datagrid,
    EditButton,
    ImageField,
    List,
    ReferenceManyField,
    Show,
    SimpleShowLayout,
    TextField,
    Pagination,
    required,
    BooleanField,
    BooleanInput,
    ReferenceArrayField,
    ReferenceArrayInput,
    ReferenceField,
    ReferenceInput,
    fetchRelatedRecords,
    downloadCSV,
    ReferenceManyCount,
    ArrayInput,
    SimpleFormIterator,
    ChipField,
} from "react-admin";
import { Edit, SimpleForm, TextInput } from "react-admin";
import { BulkActionButtons } from "./common";
import { MediaUploadField, ReferenceWithImageInput, ShareLink, ShortDateField } from "./customFields";
import { DEFAULT_IS_PUBLIC } from "./config";
import { dataProvider } from "./dataProvider";
import jsonExport from "jsonexport/dist";
import { ArtistReferenceInput } from "./artists";
import { PlaylistReferenceInput } from "./playlists";
import { OrderedSingleFieldList } from "./orderedSingleFieldList";

const artistFilters = [
    <TextInput source="q" label="Search" alwaysOn />,
    <BooleanInput source="is_public" label="Public" defaultValue={true} />,
    <BooleanInput source="has_image" label="Has image" defaultValue={true} />,
    <ReferenceInput source="distributor_id" reference="distributors" />,
    <ReferenceArrayInput source="genre_ids" label="Genre" reference="genres" />,
];

const exporter = async (labels: any) => {
    let distributors = await fetchRelatedRecords(dataProvider)(labels, "distributor_ids", "distributors");

    const data = labels.map((label: any) => {
        let distributor_names = label.distributor_ids.map(
            (distributor_id: string) => distributors[distributor_id].name
        );

        return {
            id: label.id,
            name: label.name,
            is_public: label.is_public,
            image_url: label.image_url,
            distributors: distributor_names.join(","),
            monthly_stream_count: label.monthly_stream_count,
        };
    });

    jsonExport(
        data,
        {
            headers: ["id", "name", "is_public", "image_url", "distributors", "monthly_stream_count"],
        },
        (err: any, csv: any) => {
            downloadCSV(csv, "labels");
        }
    );
};

export const LabelList = () => (
    <List
        filters={artistFilters}
        perPage={50}
        pagination={<Pagination rowsPerPageOptions={[25, 50, 100, 200]} />}
        exporter={exporter}
        sort={{ field: "created_at", order: "DESC" }}
    >
        <Datagrid rowClick="show" bulkActionButtons={<BulkActionButtons />}>
            <TextField source="name" />
            <ImageField source="image_url" title="Label image" label="Artwork" sortable={false} />
            <TextField source="monthly_stream_count" label="Monthly stream count" />
            <BooleanField source="is_public" label="Public" />
            <ShortDateField source="created_at" label="Added on" />
            <ReferenceArrayField label="Distributors" reference="distributors" source="distributor_ids" />
            <EditButton />
        </Datagrid>
    </List>
);

export const LabelShow = () => (
    <Show>
        <SimpleShowLayout>
            <h2>Label Details</h2>

            <TextField source="name" />
            <ImageField source="image_url" title="Label image" label="Artwork" />
            <BooleanField source="is_public" />
            <TextField source="id" />
            <ReferenceArrayField label="Distributors" reference="distributors" source="distributor_ids" />
            <ShareLink type="label" />
            <ReferenceManyCount label="Nb tracks" reference="tracks" target="label_id" link />
            <ReferenceManyField
                label="Tracks preview (25 max)"
                reference="tracks"
                target="label_id"
                sort={{ field: "created_at", order: "DESC" }}
            >
                <Datagrid rowClick="show">
                    <TextField source="name" />
                    <ImageField source="thumbnail_url" title="Album cover" label="Image" />
                    <ReferenceArrayField
                        label="Artists"
                        reference="artists"
                        source="artist_ids"
                        sortable={false}
                    >
                        <OrderedSingleFieldList source="artist_ids">
                            <ChipField source="name" size="small" />
                        </OrderedSingleFieldList>
                    </ReferenceArrayField>
                    <ReferenceArrayField
                        label="Remixers"
                        reference="artists"
                        source="remixer_artist_ids"
                        sortable={false}
                    >
                        <OrderedSingleFieldList source="remixer_artist_ids">
                            <ChipField source="name" size="small" />
                        </OrderedSingleFieldList>
                    </ReferenceArrayField>
                    <TextField source="stream_count" label="Stream count" />
                    <ShortDateField source="release_date" label="Release date" />
                    <BooleanField source="is_available" label="Available" />
                    <ReferenceArrayField label="Genres" reference="genres" source="genre_ids" />
                </Datagrid>
            </ReferenceManyField>
        </SimpleShowLayout>
    </Show>
);

export const LabelEdit = () => (
    <Edit mutationMode="pessimistic">
        <SimpleForm>
            <h2>Edit Label</h2>

            <TextInput
                sx={{ width: 500 }}
                autoComplete="off"
                validate={[required()]}
                fullWidth
                source="name"
                label="Label Name"
            />
            <BooleanInput source="is_public" defaultValue={DEFAULT_IS_PUBLIC} />
            <MediaUploadField mediaType="image" requiresValidation={false} />
            <ArrayInput source="top_artist_ids" fullWidth label="Top artists">
                <SimpleFormIterator inline>
                    <ArtistReferenceInput />
                </SimpleFormIterator>
            </ArrayInput>
            <ArrayInput source="featured_playlist_ids" fullWidth label="Featured Playlists">
                <SimpleFormIterator inline>
                    <PlaylistReferenceInput />
                </SimpleFormIterator>
            </ArrayInput>
        </SimpleForm>
    </Edit>
);

export const LabelCreate = () => (
    <Create>
        <SimpleForm>
            <h2>Add Label</h2>

            <TextInput
                sx={{ width: 500 }}
                autoComplete="off"
                validate={[required()]}
                fullWidth
                source="name"
                label="Label Name"
            />
            <BooleanInput source="is_public" defaultValue={DEFAULT_IS_PUBLIC} />
            <MediaUploadField mediaType="image" requiresValidation={false} />
        </SimpleForm>
    </Create>
);

export const LabelReferenceInput = (props: { label?: string; source?: string }) => {
    return (
        <ReferenceWithImageInput
            reference="labels"
            label={props.label || "Label"}
            source={props.source || "label_id"}
        />
    );
};
