import {
    useRecordContext,
    required,
    FileField,
    FileInput,
    FormDataConsumer,
    useNotify,
    Toolbar,
    SaveButton,
    DateField,
    DateFieldProps,
    ReferenceInput,
    AutocompleteInput,
    ReferenceInputProps,
    TextInput,
    InputProps,
    useInput,
    FieldTitle,
    ValidationError,
    useGetOne,
    FilterPayload,
    useRedirect,
} from "react-admin";
import { useFormContext } from "react-hook-form";
// @ts-ignore
import XMLViewer from "react-xml-viewer";
import CodeMirror from "@uiw/react-codemirror";
import { json } from "@codemirror/lang-json";
import { ReactElement, useState } from "react";
import { TimeField } from "@mui/x-date-pickers/TimeField";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs, { Dayjs } from "dayjs";
import utc from "dayjs/plugin/utc";
import duration from "dayjs/plugin/duration";
import relativeTime from "dayjs/plugin/relativeTime";

dayjs.extend(utc);
dayjs.extend(duration);
dayjs.extend(relativeTime);

export const DurationField = ({ source }: any) => {
    const record = useRecordContext();

    var timeString;
    if (record && record[source]) {
        var date = new Date(0);
        date.setSeconds(record[source] / 1000);
        timeString = date.toISOString().substring(11, 19);
    } else {
        timeString = "";
    }
    return <span>{timeString}</span>;
};

function timeStringToMilliseconds(time: Dayjs): number {
    const hoursMs = time.hour() * 60 * 60 * 1000;
    const minutesMs = time.minute() * 60 * 1000;
    const secondsMs = time.second() * 1000;

    return hoursMs + minutesMs + secondsMs;
}

export const TimeMSInput = (props: { source: string; label?: string }) => {
    const { field } = useInput(props);

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <TimeField
                onChange={(newValue) => {
                    if (newValue) {
                        field.onChange(timeStringToMilliseconds(newValue));
                    }
                }}
                defaultValue={dayjs.utc(field.value)}
                format="HH:mm:ss"
                label={props.label}
            />
        </LocalizationProvider>
    );
};

export const AudioPlayerField = () => {
    const record = useRecordContext();

    if (record.audio_url == null) {
        return <></>;
    } else {
        return (
            <audio controls src={record.audio_url}>
                Your browser does not support the audio element.
            </audio>
        );
    }
};

export const XMLField = (props: { source: string }) => {
    const record = useRecordContext();

    return (
        <div style={{ fontSize: "13px" }}>
            <XMLViewer xml={record[props.source]} />
        </div>
    );
};

export const JSONInput = (props: InputProps) => {
    const [isValid, setIsValid] = useState(true);

    const validate = () => {
        if (!isValid) {
            return "Could not parse JSON";
        }
    };

    const onChange = (value: string) => {
        try {
            field.onChange(JSON.parse(value));
            setIsValid(true);
        } catch {
            setIsValid(false);
        }
    };

    const record = useRecordContext();

    const {
        field,
        fieldState: { error },
    } = useInput({ ...props, validate });

    return (
        <div>
            <FieldTitle
                label={props.label}
                source={props.source}
                resource={props.resource}
                isRequired={props.isRequired}
            />
            <div
                style={{
                    resize: "vertical",
                    overflow: "auto",
                }}
            >
                <CodeMirror
                    value={JSON.stringify(record[props.source], null, 2)}
                    extensions={[json()]}
                    onChange={onChange}
                    style={{ width: "1000px", maxHeight: "500px" }}
                />
                {error && error?.message && (
                    <span style={{ color: "red" }}>
                        <ValidationError error={error.message} />
                    </span>
                )}
            </div>
        </div>
    );
};

export const MediaUploadField = ({ mediaType, requiresValidation }: any) => {
    const validationProps = requiresValidation ? { validate: [required()] } : {};

    const PreviewComponent = mediaType === "audio" ? AudioPreview : ArtworkPreview;
    const label = mediaType === "audio" ? "Audio File" : "Artwork";

    return (
        <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div style={{ flex: 2, maxWidth: "500px" }}>
                <FileInput {...validationProps} fullWidth source={mediaType} label={label}>
                    <FileField source="src" title="title" />
                </FileInput>

                <PreviewComponent />
            </div>
        </div>
    );
};

export const ArtworkPreview = () => (
    <FormDataConsumer>
        {({ formData }) => {
            if (formData.image && formData.image.rawFile) {
                const objectURL = URL.createObjectURL(formData.image.rawFile);
                return (
                    <img
                        src={objectURL}
                        alt="Artwork Preview"
                        style={{ maxWidth: "100%", maxHeight: "300px" }}
                    />
                );
            } else if (formData.image_url) {
                return (
                    <img
                        src={formData.image_url}
                        alt="Artwork Preview"
                        style={{ maxWidth: "100%", maxHeight: "300px" }}
                    />
                );
            } else {
                return (
                    <div style={{ padding: "1rem", border: "1px dashed #ccc" }}>
                        Artwork preview will appear here after upload
                    </div>
                );
            }
        }}
    </FormDataConsumer>
);

export const AudioPreview = () => (
    <FormDataConsumer>
        {({ formData }) => {
            if (formData.audio && formData.audio.src) {
                return (
                    <audio controls src={formData.audio.src}>
                        Your browser does not support the audio element.
                    </audio>
                );
            } else if (formData.audio_url && formData.audio_url) {
                return (
                    <audio controls src={formData.audio_url}>
                        Your browser does not support the audio element.
                    </audio>
                );
            } else {
                return (
                    <div style={{ padding: "1rem", border: "1px dashed #ccc" }}>
                        Audio preview will appear here after upload
                    </div>
                );
            }
        }}
    </FormDataConsumer>
);

export const SaveAndAddAnotherToolbar = () => {
    const notify = useNotify();
    const { reset } = useFormContext();

    return (
        <Toolbar style={{ gap: "10px" }}>
            <SaveButton />
            <SaveButton
                type="button"
                label="Save and add another"
                mutationOptions={{
                    onSuccess: () => {
                        reset();
                        window.scrollTo(0, 0);
                        notify("ra.notification.created", {
                            type: "info",
                            messageArgs: { smart_count: 1 },
                        });
                    },
                }}
            />
        </Toolbar>
    );
};

export const LongDateField = (props: DateFieldProps) => (
    <DateField
        {...props}
        options={{
            weekday: "long",
            year: "numeric",
            month: "long",
            day: "numeric",
            hour: "numeric",
            minute: "2-digit",
            hour12: false,
        }}
    />
);

export const ShortDateField = ({ source }: any) => {
    const record = useRecordContext();

    var timeString;
    if (record && record[source]) {
        var date = new Date(record[source]);
        timeString = date.toISOString().slice(0, 10);
    } else {
        timeString = "";
    }
    return <span>{timeString}</span>;
};

export const NullableReferenceInput = (props: ReferenceInputProps) => {
    const record = useRecordContext();
    const recordExists = !!record;

    return (
        <ReferenceInput {...props}>
            <AutocompleteInput defaultValue={recordExists ? undefined : null} fullWidth />
        </ReferenceInput>
    );
};

export const ColorField = (props: { source: string }) => {
    const record = useRecordContext();

    return record[props.source] ? <input type="color" value={record[props.source]} disabled /> : <></>;
};

export const ColorInput = (props: { source: string }) => {
    return (
        <>
            <FormDataConsumer>
                {({ formData }) => <input type="color" value={formData[props.source]} disabled />}
            </FormDataConsumer>

            <div style={{ display: "flex", gap: "10px", flexDirection: "column" }}>
                <TextInput fullWidth source={props.source} label="Color" />
            </div>
        </>
    );
};

const ModelRenderer = () => {
    const record = useRecordContext();
    return (
        <div style={{ display: "flex", gap: "5px", alignItems: "center" }}>
            <img src={record.thumbnail_url} style={{ width: "40px" }} />
            {record.name}
        </div>
    );
};
const referenceOptionText = <ModelRenderer />;
const referenceInputText = (choice: any) => `${choice.name}`;

export const ReferenceWithImageInput = (props: {
    label: string;
    source: string;
    reference: string;
    filter?: FilterPayload;
    create?: ReactElement;
}) => {
    let { source, reference } = props;
    const { field } = useInput({ source });
    const { data } = useGetOne(reference, { id: field.value }, { enabled: !!field.value });
    const redirect = useRedirect();

    return (
        <div
            style={{
                display: "flex",
                gap: "5px",
                alignItems: "center",
                height: "78px",
            }}
        >
            <div
                onClick={() => data && redirect("show", reference, data.id)}
                style={{ cursor: data ? "pointer" : "default" }}
            >
                <img
                    src={
                        data?.thumbnail_url ||
                        "data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='40' height='40'%3E%3Crect width='40' height='40' fill='%23eee'/%3E%3C/svg%3E"
                    }
                    style={{ width: "40px", height: "40px", marginTop: "24px" }}
                    alt=""
                    title={data ? "Click to view details" : ""}
                />
            </div>
            <ReferenceInput
                fullWidth
                source={source}
                reference={props.reference}
                label={props.label}
                sort={{ field: "_search", order: "DESC" }}
                filter={props.filter}
            >
                <AutocompleteInput
                    inputText={referenceInputText}
                    optionText={referenceOptionText}
                    fullWidth
                    style={{ minWidth: "300px", maxWidth: "500px", maxHeight: "48px" }}
                    create={props.create}
                />
            </ReferenceInput>
        </div>
    );
};

export const RelativeDateTimeField = ({ source }: { source: string }) => {
    const record = useRecordContext();
    if (!record || !record[source]) return null;

    const date = dayjs(record[source]);
    return (
        <span title={date.format("YYYY-MM-DD HH:mm:ss")}>
            {date.format("YYYY-MM-DD HH:mm:ss")} ({date.fromNow()})
        </span>
    );
};

export const TrackReferenceInput = (props: { label?: string; source?: string; filter?: FilterPayload }) => {
    return (
        <ReferenceWithImageInput
            reference="tracks"
            label={props.label || "Track"}
            source={props.source || "track_id"}
            filter={props.filter}
        />
    );
};

export const ShareLink = (props: { type: string }) => {
    const record = useRecordContext();
    if (!record) return null;
    const link = `${import.meta.env.VITE_APP_SHARE_URL}/${props.type}/${record.id}`;
    return (
        <p>
            Share: <a href={link}>{link}</a>
        </p>
    );
};
