import {
    Create,
    Datagrid,
    EditButton,
    ImageField,
    List,
    ReferenceField,
    ReferenceManyField,
    Show,
    SimpleShowLayout,
    TextField,
    Pagination,
    required,
    BooleanField,
    BooleanInput,
    DateInput,
    DateField,
    ReferenceArrayInput,
    ReferenceArrayField,
    ArrayField,
    SingleFieldList,
    ChipField,
    ArrayInput,
    SimpleFormIterator,
    fetchRelatedRecords,
    downloadCSV,
    DatagridConfigurable,
    WrapperField,
    TopToolbar,
    SelectColumnsButton,
    FilterButton,
    CreateButton,
    ExportButton,
    AutocompleteArrayInput,
    SelectInput,
} from "react-admin";
import { Edit, ReferenceInput, SimpleForm, TextInput } from "react-admin";
import {
    DurationField,
    LongDateField,
    MediaUploadField,
    ReferenceWithImageInput,
    SaveAndAddAnotherToolbar,
    ShortDateField,
} from "./customFields";
import { NamingCallout } from "./callouts";
import { captureError } from "./sentry";
import { VisibilityBulkActionButtons } from "./common";

import { ArtistReferenceInput } from "./artists";
import { LabelReferenceInput } from "./labels";
import Tooltip from "@mui/material/Tooltip";
import { TrackReferenceInput } from "./customFields";
import { DEFAULT_IS_PUBLIC } from "./config";
import { dataProvider } from "./dataProvider";
import jsonExport from "jsonexport/dist";
import { TagCreatePopup } from "./tags";
import { OrderedSingleFieldList } from "./orderedSingleFieldList";
import { AutocompleteInput, BulkDeleteButton, BulkExportButton, BulkUpdateButton } from "react-admin";
import { Visibility, VisibilityOff, Audiotrack, Home } from "@mui/icons-material";
import { BulkUpdateForm } from "./bulkUpdate";

const albumTypes = [
    { id: "album", name: "Album" },
    { id: "single", name: "Single" },
    { id: "ep", name: "EP" },
];

const albumFilters = [
    <TextInput source="q" label="Search" alwaysOn />,
    <BooleanInput source="is_public" label="Public" defaultValue={true} />,
    <ArtistReferenceInput source="artist_id" label="Artist" />,
    <LabelReferenceInput source="label_id" label="Label" />,
    <ReferenceArrayInput source="genre_ids" label="Genre" reference="genres" />,
    <ReferenceArrayInput source="tag_ids" label="Tag" reference="tags" />,
    <BooleanInput source="is_ddex_upload" label="Has been uploaded with DDEX" defaultValue={true} />,
    <ReferenceInput source="distributor_id" reference="distributors" />,
    <BooleanInput source="missing_distributor" label="Missing distributor" defaultValue={true} />,
];

const exporter = async (albums: any) => {
    let genres = await fetchRelatedRecords(dataProvider)(albums, "genre_ids", "genres");
    let artists = await fetchRelatedRecords(dataProvider)(albums, "artist_ids", "artists");

    const data = albums.map((album: any) => {
        let artist_names = album.artist_ids.map((artist_id: string) => artists[artist_id].name);

        return {
            id: album.id,
            name: album.name,
            artist_names: artist_names.join(", "),
            genres: album.genre_ids.map((genre_id: number) => genres[genre_id].name),
            is_public: album.is_public,
            image_url: album.image_url,
        };
    });

    jsonExport(
        data,
        {
            headers: ["id", "name", "artist_names", "genres", "is_public", "image_url"],
        },
        (err: any, csv: any) => {
            downloadCSV(csv, "albums");
        }
    );
};

const AlbumActions = () => (
    <TopToolbar>
        <SelectColumnsButton />
        <FilterButton />
        <CreateButton />
        <ExportButton />
    </TopToolbar>
);

const AlbumBulkActionButtons = () => (
    <>
        <BulkUpdateButton
            label="Make public"
            data={{ is_public: true }}
            icon={<Visibility />}
            mutationMode="pessimistic"
        />
        <BulkUpdateButton
            label="Make private"
            data={{ is_public: false }}
            icon={<VisibilityOff />}
            mutationMode="pessimistic"
        />
        <BulkUpdateForm label="Update label" icon={<Audiotrack />}>
            <LabelReferenceInput />
        </BulkUpdateForm>
        <BulkUpdateForm label="Update distributor" icon={<Home />}>
            <ReferenceInput source="distributor_id" reference="distributors">
                <AutocompleteInput fullWidth />
            </ReferenceInput>
        </BulkUpdateForm>
        <BulkExportButton />
        <BulkDeleteButton mutationMode="pessimistic" />
    </>
);

export const AlbumList = () => (
    <List
        filters={albumFilters}
        perPage={50}
        pagination={<Pagination rowsPerPageOptions={[25, 50, 100, 200]} />}
        sort={{ field: "created_at", order: "DESC" }}
        exporter={exporter}
        actions={<AlbumActions />}
    >
        <DatagridConfigurable
            rowClick="show"
            bulkActionButtons={<AlbumBulkActionButtons />}
            omit={["is_ddex_available", "release_date", "label_id", "distributor_id"]}
        >
            <TextField source="name" />
            <ImageField source="thumbnail_url" title="Album cover" label="Image" sortable={false} />
            <ReferenceArrayField label="Artists" reference="artists" source="artist_ids">
                <OrderedSingleFieldList source="artist_ids">
                    <ChipField source="name" size="small" />
                </OrderedSingleFieldList>
            </ReferenceArrayField>
            <ReferenceArrayField label="Genres" reference="genres" source="genre_ids" sortable={false} />
            <ReferenceArrayField label="Tags" reference="tags" source="tag_ids" sortable={false} />
            <ReferenceField source="label_id" reference="labels" label="Label" link="show" />
            <ReferenceField
                source="distributor_id"
                reference="distributors"
                label="Distributor"
                link="show"
            />
            <BooleanField source="is_public" label="Public" />
            <BooleanField source="is_ddex_available" label="DDEX Available" />
            <ShortDateField source="created_at" label="Added on" />
            <ShortDateField source="release_date" label="Release_date" />
            <WrapperField label="Edit">
                <EditButton />
            </WrapperField>
        </DatagridConfigurable>
    </List>
);

export const AlbumShow = () => (
    <Show>
        <SimpleShowLayout>
            <h2>Album Details</h2>

            <TextField source="name" />
            <TextField source="description" />
            <ImageField source="image_url" title="Album cover" label="Image" />
            <ArrayField source="status" label="Processing Status">
                <SingleFieldList linkType={false}>
                    <ChipField source="name" size="small" />
                </SingleFieldList>
            </ArrayField>
            <TextField source="id" />
            <ReferenceArrayField label="Artists" reference="artists" source="artist_ids">
                <OrderedSingleFieldList source="artist_ids">
                    <ChipField source="name" size="small" />
                </OrderedSingleFieldList>
            </ReferenceArrayField>
            <ReferenceArrayField label="Genres" reference="genres" source="genre_ids" />
            <BooleanField source="is_public" />
            <BooleanField source="is_ddex_available" />
            <ReferenceField source="label_id" reference="labels" label="Label" link="show" />
            <ReferenceField
                source="distributor_id"
                reference="distributors"
                label="Distributor"
                link="show"
            />
            <LongDateField source="created_at" label="Added on" />
            <DateField source="release_date" />
            <ReferenceArrayField label="Tags" reference="tags" source="tag_ids" />
            <ReferenceArrayField label="Tracks" reference="tracks" source="track_ids">
                <Datagrid rowClick="show">
                    <TextField source="name" />
                    <ImageField source="thumbnail_url" title="Album cover" label="Image" />
                    <ReferenceArrayField
                        label="Artists"
                        reference="artists"
                        source="artist_ids"
                        sortable={false}
                    >
                        <OrderedSingleFieldList source="artist_ids">
                            <ChipField source="name" size="small" />
                        </OrderedSingleFieldList>
                    </ReferenceArrayField>
                    <ReferenceArrayField
                        label="Genres"
                        reference="genres"
                        source="genre_ids"
                        sortable={false}
                    />
                    <BooleanField source="is_public" label="Public" />
                    <DurationField source="duration_ms" label="Duration" />
                </Datagrid>
            </ReferenceArrayField>
        </SimpleShowLayout>
    </Show>
);

const validateAlbum = (values: any) => {
    try {
        const errors: any = {};
        console.log(values);
        if (values.genre_ids && values.genre_ids.length === 0) {
            errors.genre_ids = "Missing genres";
        }
        if (values.description && values.description.length > 1000) {
            errors.description = `Description too big (${values.description.length}): 1000 chars maximum`;
        }
        if (
            !values.artist_ids ||
            values.artist_ids.length == 0 ||
            !values.artist_ids.every((element: string) => element !== "")
        ) {
            errors.artist_ids = "Missing artists";
        }
        if (!values.type) {
            errors.type = "Missing type";
        }
        if (!values.distributor_id) {
            errors.distributor_id = "Missing distributor";
        }
        // if (!values.image) {
        //     errors.image = "Missing image";
        // }
        return errors;
    } catch (error) {
        captureError(error);
        throw error;
    }
};

export const AlbumEdit = () => (
    <Edit mutationMode="pessimistic">
        <SimpleForm validate={validateAlbum}>
            <h2>Edit Album</h2>

            <AlbumDetailsEditor />

            <h2>Media Upload</h2>

            <MediaUploadField mediaType="image" requiresValidation={false} />
            <TextInput sx={{ width: 500 }} source="id" InputProps={{ disabled: true }} />
        </SimpleForm>
    </Edit>
);

export const AlbumCreate = () => (
    <Create>
        <SimpleForm validate={validateAlbum} toolbar={<SaveAndAddAnotherToolbar />}>
            <h2>Add Album</h2>

            <AlbumDetailsEditor />

            <h2>Media Upload</h2>

            <MediaUploadField mediaType="image" requiresValidation={false} />
        </SimpleForm>
    </Create>
);

export const AlbumDetailsEditor = () => (
    <div>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div style={{ flex: 2, maxWidth: "500px" }}>
                <TextInput
                    autoComplete="off"
                    validate={[required()]}
                    fullWidth
                    source="name"
                    label="Album Name"
                />
                <TextInput
                    sx={{ width: 500 }}
                    autoComplete="off"
                    multiline={true}
                    fullWidth
                    source="description"
                    label="Album Description"
                />
                <ArrayInput source="artist_ids" fullWidth label="Artists">
                    <SimpleFormIterator inline>
                        <ArtistReferenceInput />
                    </SimpleFormIterator>
                </ArrayInput>
                <LabelReferenceInput />
                <ReferenceInput source="distributor_id" reference="distributors" />
                <ReferenceArrayInput label="Genres" source="genre_ids" reference="genres" />
                <BooleanInput source="is_public" defaultValue={DEFAULT_IS_PUBLIC} />
                <Tooltip title="Release date shown in the app, only informational">
                    <DateInput source="release_date" />
                </Tooltip>
            </div>

            <div style={{ flex: 1, marginLeft: "20px" }}>
                <NamingCallout />
            </div>
        </div>
        <SelectInput source="type" choices={albumTypes} validate={[required()]} label="Album type" />
        <ArrayInput source="track_ids" fullWidth label="Tracks">
            <SimpleFormIterator inline>
                <TrackReferenceInput />
            </SimpleFormIterator>
        </ArrayInput>
        <ReferenceArrayInput source="tag_ids" reference="tags">
            <AutocompleteArrayInput label="Tags" create={<TagCreatePopup />} />
        </ReferenceArrayInput>
    </div>
);

export const AlbumReferenceInput = (props: { label?: string; source?: string }) => {
    return (
        <ReferenceWithImageInput
            reference="albums"
            label={props.label || "Album"}
            source={props.source || "album_id"}
        />
    );
};
